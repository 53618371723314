
import { ref, defineComponent, onUpdated, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import "firebase/firebase-messaging";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPage,
  IonInput,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonLoading,
  IonImg,
  IonToast
  
} from "@ionic/vue";

import { mail, call, business, person, constructOutline } from "ionicons/icons";
import { addIcons } from "ionicons";
import * as eventBus from "@/services/event.bus.js";
import { JnService } from "@/services/jn.service";
import { Mail } from "@/models/mail.model";
import { EmailTemplates } from "@/models/enums.model";
addIcons({
  mail: mail,
  call: call,
  business: business,
  person: person
  /* 'arrow-forward': arrowForward,
  'arrow-down': arrowDown,*/
});
export default defineComponent({
  name: "JnLogin",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonInput,
    IonLoading,
    IonContent,
    IonImg,
    IonIcon,
    IonCard,
    IonCardContent,
    IonButton,
    IonToast
  },
  setup() {
    const identificativo = ref("");
    const email = ref("");
    const citta = ref("");
    const phoneNumber = ref("");

    const store = useStore();
    const router = useRouter();

    const showLoading = ref(false);
    const loadingMessage = ref("Sto inviando la richiesta di registrazione...");

    const alertMessage = ref("");
    const showAlert = ref(false);
    const setAlertOpen = (state: boolean) => (showAlert.value = state);

    async function cancel() {
      router.push("/login");
    }

    async function canSubmit() {
      const canSubmit = identificativo.value && identificativo.value.trim().length > 0 &&
              email.value && email.value.trim().length > 0 &&
              citta.value && citta.value.trim().length > 0 &&
              phoneNumber.value && phoneNumber.value.trim().length > 0
      console.log(canSubmit)
      return canSubmit;
    }

    async function register() {      
      if (await canSubmit()) {
        showLoading.value = true;
        await JnService.sendmail(
          new Mail({
            to: process.env.VUE_APP_JURANEWS_MAIL!,
            template: EmailTemplates.RICHIESTA_TRIAL,
            locals: {
              identificativo: identificativo.value,
              citta: citta.value,
              phoneNumber: phoneNumber.value,
              username: email.value,
            },
          })
        );
        showLoading.value = false
        alertMessage.value = "Richiesta inviata"
        showAlert.value = true
        await cancel()
      }
      else {
        
        if (!identificativo.value || identificativo.value.length == 0) {
          alertMessage.value = "Dati identificativi obbligatori"
        }
        else if (!email.value || email.value.length == 0) {
          alertMessage.value = "Email obbligatoria"
        }
        else if (!citta.value || citta.value.length == 0) {
          alertMessage.value = "Città obbligatoria"
        }
        else if (!phoneNumber.value || phoneNumber.value.length == 0) {
          alertMessage.value = "Numero di telefono obbligatorio"
        }
        showAlert.value = true
      }
    }

    return {
      register,
      cancel,
      setAlertOpen,
      canSubmit,
      alertMessage,
      showAlert,
      identificativo,
      email,
      citta,
      phoneNumber,
      showLoading,
      loadingMessage
    };
  },
});
